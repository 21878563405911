import { BusinessEntityUserRole, DashboardRouteAreaAccess } from '@/api';
import { RouteLocationNormalized, RouteRecordRaw, RouteMeta } from 'vue-router';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
    role?: BusinessEntityUserRole;
    area: DashboardRouteAreaAccess;
}

export enum RouteNames {
    AUTH = 'AUTH',
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
    HOME = 'HOME',
    DASHBOARD = 'DASHBOARD',
    LIVE_VIEW = 'LIVE_VIEW',
    RESOURCES = 'RESOURCES',
    RESOURCES_OVERVIEW = 'RESOURCES_OVERVIEW',
    RESOURCES_CATEGORIES = 'RESOURCES_CATEGORIES',
    RESOURCES_CAPACITY_THRESHOLDS = 'RESOURCES_CAPACITY_THRESHOLDS',
    STORAGES = 'STORAGES',
    TASKS = 'TASKS',
    TASKS_SEARCH = 'TASKS_SEARCH',
    TASKS_INVOICES = 'TASKS_INVOICES',
    TASKS_SCHEDULER = 'TASKS_SCHEDULER',
    TASKS_SCHEDULER_CREATE = 'TASKS_SCHEDULER_CREATE',
    TASKS_SCHEDULER_UPDATE = 'TASKS_SCHEDULER_UPDATE',
    TASKS_DEVIATIONS = 'TASKS_DEVIATIONS',
    TASKS_IMPORT = 'TASKS_IMPORT',
    CUSTOMERS = 'CUSTOMERS',
    CUSTOMERS_CUSTOMER = 'CUSTOMERS_CUSTOMER',
    CUSTOMERS_CUSTOMER_INFORMATION = 'CUSTOMERS_CUSTOMER_INFORMATION',
    CUSTOMERS_CUSTOMER_SHIPMENT_TYPES = 'CUSTOMERS_CUSTOMER_SHIPMENT_TYPES',
    CUSTOMERS_CUSTOMER_SHIPMENT_TYPE_CATEGORIES = 'CUSTOMERS_CUSTOMER_SHIPMENT_TYPE_CATEGORIES',
    CUSTOMERS_CUSTOMER_DELIVERY_PRICES = 'CUSTOMERS_CUSTOMER_DELIVERY_PRICES',
    CUSTOMERS_CUSTOMER_DELIVERY_ADDRESSES = 'CUSTOMERS_CUSTOMER_DELIVERY_ADDRESSES',
    CUSTOMERS_CUSTOMER_USERS = 'CUSTOMERS_CUSTOMER_USERS',
    CUSTOMERS_CUSTOMER_FEES = 'CUSTOMERS_CUSTOMER_FEES',
    CUSTOMERS_CUSTOMER_TASKS = 'CUSTOMERS_CUSTOMER_TASKS',
    CUSTOMERS_OVERVIEW = 'CUSTOMERS_OVERVIEW',
    EMPLOYEES = 'EMPLOYEES',
    EMPLOYEES_ADMINS = 'EMPLOYEES_ADMINS',
    EMPLOYEES_MODERATORS = 'EMPLOYEES_MODERATORS',
    EMPLOYEES_CARRIER = 'EMPLOYEES_CARRIER',
    EMPLOYEES_PRESTART_CHECKS = 'EMPLOYEES_PRESTART_CHECKS',
    DICTIONARY = 'DICTIONARY',
    DICTIONARY_SHIPPING = 'DICTIONARY_SHIPPING',
    DICTIONARY_DELIVERY = 'DICTIONARY_DELIVERY',
    DICTIONARY_LABELS_SHIPPING = 'DICTIONARY_LABELS_SHIPPING',
    DICTIONARY_LABELS_DELIVERY = 'DICTIONARY_LABELS_DELIVERY',
    DICTIONARY_LANGUAGES_SHIPPING = 'DICTIONARY_LANGUAGES_SHIPPING',
    DICTIONARY_LANGUAGES_DELIVERY = 'DICTIONARY_LANGUAGES_DELIVERY',
    STATISTICS = 'STATISTICS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    ADMINISTRATION = 'ADMINISTRATION',
    ADMINISTRATION_SETTINGS = 'ADMINISTRATION_SETTINGS',
    ADMINISTRATION_SHIPMENT_PDF_BUILDER = 'ADMINISTRATION_SHIPMENT_PDF_BUILDER',
    ADMINISTRATION_DELIVERY_OVERVIEW_PDF_BUILDER = 'ADMINISTRATION_DELIVERY_OVERVIEW_PDF_BUILDER',
    ADMINISTRATION_ENDPOINT_ACCESSES = 'ADMINISTRATION_ENDPOINT_ACCESSES',
    ADMINISTRATION_AZURE_AD = 'ADMINISTRATION_AZURE_AD',
    ADMINISTRATION_AZURE_AD_LOGIN_SUCCESS = 'ADMINISTRATION_AZURE_AD_LOGIN_SUCCESS',
    ADMINISTRATION_MAIL_INTEGRATION = 'ADMINISTRATION_MAIL_INTEGRATION',
    ADMINISTRATION_MAIL_INTEGRATION_INBOX = 'ADMINISTRATION_MAIL_INTEGRATION_INBOX',
    ADMINISTRATION_MAIL_INTEGRATION_INBOX_RULES = 'ADMINISTRATION_MAIL_INTEGRATION_INBOX_RULES',
    ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATIONS = 'ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATIONS',
    ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATION = 'ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATION',
}

const createAuthenticatedPath = (...paths: string[]) => {
    return `/:businessEntity:/${paths.join('/')}`;
};

export const routes: Array<RouteRecordRaw> = [
    {
        path: createAuthenticatedPath(''),
        name: RouteNames.HOME,
        component: () => import('@/pages/dashboard/Index.vue'),
        redirect: {
            name: RouteNames.DASHBOARD,
        },
        children: [
            {
                path: createAuthenticatedPath('dashboard'),
                name: RouteNames.DASHBOARD,
                component: () => import('@/pages/dashboard/Dashboard.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: DashboardRouteAreaAccess.Dashboard,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('live-view'),
                name: RouteNames.LIVE_VIEW,
                component: () => import('@/pages/dashboard/live-view/LiveView.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: false,
                    area: DashboardRouteAreaAccess.LiveView,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('resources'),
                name: RouteNames.RESOURCES,
                component: () => import('@/pages/dashboard/resources/Resources.vue'),
                redirect: {
                    name: RouteNames.RESOURCES_OVERVIEW,
                },
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Resources,
                } as RouteMetaExtended,
                children: [
                    {
                        path: createAuthenticatedPath('resources/overview'),
                        name: RouteNames.RESOURCES_OVERVIEW,
                        component: () => import('@/pages/dashboard/resources/ResourcesOverview.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Resources,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('resources/categories'),
                        name: RouteNames.RESOURCES_CATEGORIES,
                        component: () => import('@/pages/dashboard/resources/ResourcesCategoriesOverview.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Resources,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('resources/capacity-thresholds'),
                        name: RouteNames.RESOURCES_CAPACITY_THRESHOLDS,
                        component: () => import('@/pages/dashboard/resources/ResourceCapacityThresholds.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Resources,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('storages'),
                name: RouteNames.STORAGES,
                component: () => import('@/pages/dashboard/storages/StoragesPage.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Storages,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('tasks'),
                name: RouteNames.TASKS,
                component: () => import('@/pages/dashboard/tasks/Index.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Tasks,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.TASKS_SEARCH },
                children: [
                    {
                        path: createAuthenticatedPath('tasks', 'search'),
                        name: RouteNames.TASKS_SEARCH,
                        component: () => import('@/pages/dashboard/tasks/SearchTasks.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'invoices'),
                        name: RouteNames.TASKS_INVOICES,
                        component: () => import('@/pages/dashboard/tasks/Invoices.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'scheduler'),
                        name: RouteNames.TASKS_SCHEDULER,
                        component: () => import('@/pages/dashboard/tasks/scheduler/TaskScheduler.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'scheduler', 'create'),
                        name: RouteNames.TASKS_SCHEDULER_CREATE,
                        component: () => import('@/pages/dashboard/tasks/scheduler/TaskScheduler_AddOrUpdate.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'scheduler', 'update', ':scheduledTaskId'),
                        name: RouteNames.TASKS_SCHEDULER_UPDATE,
                        component: () => import('@/pages/dashboard/tasks/scheduler/TaskScheduler_AddOrUpdate.vue'),
                        props: (route: RouteLocationNormalized) => ({
                            scheduledTaskId: route.params.scheduledTaskId,
                        }),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'deviations'),
                        name: RouteNames.TASKS_DEVIATIONS,
                        component: () => import('@/pages/dashboard/tasks/deviations/Deviations.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('tasks', 'import'),
                        name: RouteNames.TASKS_IMPORT,
                        component: () => import('@/pages/dashboard/tasks/import/Import.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Tasks,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('customers'),
                name: RouteNames.CUSTOMERS,
                component: () => import('@/pages/dashboard/customers/Customers.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Customers,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.CUSTOMERS_OVERVIEW },
                children: [
                    {
                        path: createAuthenticatedPath('customers', 'overview'),
                        name: RouteNames.CUSTOMERS_OVERVIEW,
                        component: () => import('@/pages/dashboard/customers/overview/CustomersOverview.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('customers', ':customerId'),
                        name: RouteNames.CUSTOMERS_CUSTOMER,
                        component: () => import('@/pages/dashboard/customers/customer/Customer.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                        redirect: { name: RouteNames.CUSTOMERS_CUSTOMER_INFORMATION },
                        props: (route: RouteLocationNormalized) => ({
                            customerId: route.params.customerId,
                        }),
                        children: [
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'information'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_INFORMATION,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerInformations.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'shipment-type-categories'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_SHIPMENT_TYPE_CATEGORIES,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerShipmentTypeCategories.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'shipment-types'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_SHIPMENT_TYPES,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerShipmentTypes.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'delivery-prices'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_DELIVERY_PRICES,
                                component: () => import('@/pages/dashboard/customers/customer/delivery-prices/CustomerDeliveryPrices.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'addresses'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_DELIVERY_ADDRESSES,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerAddresses.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'users'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_USERS,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerUsers.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'delivery-fees'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_FEES,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerFees.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('customers', ':customerId', 'tasks'),
                                name: RouteNames.CUSTOMERS_CUSTOMER_TASKS,
                                component: () => import('@/pages/dashboard/customers/customer/CustomerTasks.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Customers,
                                } as RouteMetaExtended,
                            },
                        ],
                    },
                ],
            },
            {
                path: createAuthenticatedPath('employees'),
                name: RouteNames.EMPLOYEES,
                component: () => import('@/pages/dashboard/employees/Employees.vue'),
                redirect: { name: RouteNames.EMPLOYEES_ADMINS },
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Employees,
                } as RouteMetaExtended,
                children: [
                    {
                        path: createAuthenticatedPath('employees', 'administrators'),
                        name: RouteNames.EMPLOYEES_ADMINS,
                        component: () => import('@/pages/dashboard/employees/Employees_Admins.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Employees,
                            role: BusinessEntityUserRole.Administrator,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('employees', 'moderators'),
                        name: RouteNames.EMPLOYEES_MODERATORS,
                        component: () => import('@/pages/dashboard/employees/Employees_Moderators.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Employees,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('employees', 'carriers'),
                        name: RouteNames.EMPLOYEES_CARRIER,
                        component: () => import('@/pages/dashboard/employees/Employees_Carriers.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Employees,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('employees', 'prestart-checks'),
                        name: RouteNames.EMPLOYEES_PRESTART_CHECKS,
                        component: () => import('@/pages/dashboard/employees/Employees_PreStartChecks.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Employees,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('dictionary'),
                name: RouteNames.DICTIONARY,
                component: () => import('@/pages/dashboard/dictionary/Index.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Dictionary,
                } as RouteMetaExtended,
                children: [
                    {
                        path: createAuthenticatedPath('dictionary', 'shipping'),
                        name: RouteNames.DICTIONARY_SHIPPING,
                        component: () => import('@/pages/dashboard/dictionary/shipping/Index.vue'),
                        redirect: { name: RouteNames.DICTIONARY_LABELS_SHIPPING },
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Dictionary,
                        } as RouteMetaExtended,
                        children: [
                            {
                                path: createAuthenticatedPath('dictionary', 'shipping', 'labels'),
                                name: RouteNames.DICTIONARY_LABELS_SHIPPING,
                                component: () => import('@/pages/dashboard/dictionary/shipping/Labels.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Dictionary,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('dictionary', 'shipping', 'languages'),
                                name: RouteNames.DICTIONARY_LANGUAGES_SHIPPING,
                                component: () => import('@/pages/dashboard/dictionary/shipping/Languages.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Dictionary,
                                } as RouteMetaExtended,
                            },
                        ],
                    },
                    {
                        path: createAuthenticatedPath('dictionary', 'delivery'),
                        name: RouteNames.DICTIONARY_DELIVERY,
                        component: () => import('@/pages/dashboard/dictionary/delivery/Index.vue'),
                        redirect: { name: RouteNames.DICTIONARY_LABELS_DELIVERY },
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Dictionary,
                        } as RouteMetaExtended,
                        children: [
                            {
                                path: createAuthenticatedPath('dictionary', 'delivery', 'labels'),
                                name: RouteNames.DICTIONARY_LABELS_DELIVERY,
                                component: () => import('@/pages/dashboard/dictionary/delivery/Labels.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Dictionary,
                                } as RouteMetaExtended,
                            },
                            {
                                path: createAuthenticatedPath('dictionary', 'delivery', 'languages'),
                                name: RouteNames.DICTIONARY_LANGUAGES_DELIVERY,
                                component: () => import('@/pages/dashboard/dictionary/delivery/Languages.vue'),
                                meta: {
                                    allowAnonymous: false,
                                    area: DashboardRouteAreaAccess.Dictionary,
                                } as RouteMetaExtended,
                            },
                        ],
                    },
                ],
            },
            {
                path: createAuthenticatedPath('statistics'),
                name: RouteNames.STATISTICS,
                component: () => import('@/pages/dashboard/statistics/Index.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Statistics,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('notifications'),
                name: RouteNames.NOTIFICATIONS,
                component: () => import('@/pages/dashboard/notifications/Notifications.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Notifications,
                } as RouteMetaExtended,
            },
            {
                path: createAuthenticatedPath('administration'),
                name: RouteNames.ADMINISTRATION,
                component: () => import('@/pages/dashboard/administration/Administration.vue'),
                redirect: { name: RouteNames.ADMINISTRATION_SETTINGS },
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Administration,
                } as RouteMetaExtended,
                children: [
                    {
                        path: createAuthenticatedPath('administration', 'settings'),
                        name: RouteNames.ADMINISTRATION_SETTINGS,
                        component: () => import('@/pages/dashboard/administration/AdministrationSettings.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('administration', 'endpoint-accesses'),
                        name: RouteNames.ADMINISTRATION_ENDPOINT_ACCESSES,
                        component: () => import('@/pages/dashboard/administration/AdministrationEndpointAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('administration', 'shipment-pdf-builder'),
                        name: RouteNames.ADMINISTRATION_SHIPMENT_PDF_BUILDER,
                        component: () => import('@/pages/dashboard/administration/AdministrationShipmentPdfBuilder.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('administration', 'delivery-overview-pdf-builder'),
                        name: RouteNames.ADMINISTRATION_DELIVERY_OVERVIEW_PDF_BUILDER,
                        component: () => import('@/pages/dashboard/administration/AdministrationTaskOverviewPdfBuilder.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('administration', 'azure-ad'),
                        name: RouteNames.ADMINISTRATION_AZURE_AD,
                        component: () => import('@/pages/dashboard/administration/AzureAd.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('azure-ad-login-successful'),
                        name: RouteNames.ADMINISTRATION_AZURE_AD_LOGIN_SUCCESS,
                        component: () => import('@/pages/dashboard/administration/AzureAdLoginSuccess.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                ],
            },
            {
                path: createAuthenticatedPath('mail-integration'),
                name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION,
                component: () => import('@/pages/dashboard/mail-integration/MailIntegration.vue'),
                meta: {
                    allowAnonymous: false,
                    area: DashboardRouteAreaAccess.Administration,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION_INBOX },
                children: [
                    {
                        path: createAuthenticatedPath('mail-integration', 'inbox'),
                        name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION_INBOX,
                        component: () => import('@/pages/dashboard/mail-integration/MailIntegrationInbox.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('mail-integration', 'inbox', 'rules'),
                        name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION_INBOX_RULES,
                        component: () => import('@/pages/dashboard/mail-integration/MailIntegrationInboxRules.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('mail-integration', 'configurations'),
                        name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATIONS,
                        component: () => import('@/pages/dashboard/mail-integration/MailIntegrationConfigurations.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createAuthenticatedPath('mail-integration', ':configurationId'),
                        name: RouteNames.ADMINISTRATION_MAIL_INTEGRATION_CONFIGURATION,
                        component: () => import('@/pages/dashboard/mail-integration/MailIntegrationConfiguration.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: DashboardRouteAreaAccess.Administration,
                        } as RouteMetaExtended,
                        props: (route: RouteLocationNormalized) => ({
                            configurationId: route.params.configurationId,
                        }),
                    },
                ],
            },
        ],
    },
    {
        path: createAuthenticatedPath('auth'),
        name: RouteNames.AUTH,
        component: () => import('@/pages/login/LoginPage.vue'),
        meta: {
            allowAnonymous: true,
            area: DashboardRouteAreaAccess.Auth,
        },
        children: [
            {
                path: createAuthenticatedPath('auth', 'login'),
                name: RouteNames.AUTH_LOGIN,
                component: () => import('@/pages/login/LoginForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: DashboardRouteAreaAccess.Auth,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'forgot-password'),
                name: RouteNames.AUTH_FORGOT_PASSWORD,
                component: () => import('@/pages/login/ForgotPasswordForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: DashboardRouteAreaAccess.Auth,
                },
            },
            {
                path: createAuthenticatedPath('auth', 'reset-password'),
                name: RouteNames.AUTH_RESET_PASSWORD,
                component: () => import('@/pages/login/ResetPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    token: route.query.token,
                }),
                meta: {
                    allowAnonymous: true,
                    area: DashboardRouteAreaAccess.Auth,
                },
            },
        ],
    },
    {
        path: '/:businessEntity:/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        meta: {
            allowAnonymous: true,
        },
    },
];
