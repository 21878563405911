<template>
    <div v-show="!dialog.minimized"
         :id="dialog.id"
         class="modal-outer">
        <div class="modal"
             role="dialog"
             aria-modal="true"
             :class="[ dialog.state, dialog.options.size ]">
            <Transition appear
                        :enter-active-class="enterActiveClass"
                        :leave-active-class="leaveActiveClass"
                        @enter="modalVisible"
                        @after-leave="modalHidden">
                <div v-if="dialogActive"
                     class="modal-dialog"
                     :class="[ dialog.options.size ]"
                     data-lock>
                    <div ref="modal"
                         class="modal-content" 
                         :disabled="!last">
                        <Suspense>
                            <component :is="dialog.component"
                                       v-bind="dialog.componentAttrs"
                                       :dialog="dialog"
                                       @close="close(false)"/>

                            <template #fallback>
                                <div class="loading">
                                    <Spinner/>
                                </div>
                            </template>
                        </Suspense>

                        <div v-if="!dialog.options.hideMinimizeButton || !dialog.options.hideCloseButton" class="dialog-tray">
                            <button 
                                v-if="!dialog.options.hideMinimizeButton"
                                class="white-theme:text-black"
                                @click.prevent="minimize()">
                                <CIcon
                                    name="window-minimize"
                                    height="20"
                                    width="20"
                                    fill/>
                            </button>
                            <button 
                                v-if="!dialog.options.hideCloseButton"
                                class="white-theme:text-black"
                                @click.prevent="close(true)">
                                <CIcon
                                    name="window-close"
                                    height="20"
                                    width="20"
                                    fill/>
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>

        <Transition appear
                    :enter-active-class="backdropEnterActiveClass"
                    :leave-active-class="backdropLeaveActiveClass">
            <div v-if="dialogActive && !dialog.minimized" 
                 class="modal-backdrop"
                 @click="() => dialog.options.closeOnBackdrop && close(true)">
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, ref, computed } from 'vue';
import { useAnimation } from '../animation/animateComposable';
import { Modal } from './dialog.service';
import { useEventListener } from '@vueuse/core';
import FocusLock from '@/core/focus-lock/FocusLock.vue';

export default defineComponent({
    name: 'Modal',
    props: {
        dialog: {
            type: Object as PropType<Modal>,
            required: true,
        },
        last: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        FocusLock,
    },
    setup(props) {
        const { dialog } = toRefs(props);
        const modal = ref<HTMLDivElement | null>(null);

        const { enterActiveClass, leaveActiveClass } = useAnimation({
            type: dialog.value.options.enterAnimation ?? 'zoom',
            enterDirection: dialog.value.options.enterDirection,
            leaveDirection: dialog.value.options.leaveDirection,
            duration: '500ms',
        });

        const { enterActiveClass: backdropEnterActiveClass, leaveActiveClass: backdropLeaveActiveClass } = useAnimation({ type: 'fade', duration: '500ms' });

        const modalVisible = () => {
            dialog.value.state = 'visible';
        };

        const modalHidden = () => {
            dialog.value.promise.resolve(props.dialog.value);
        };

        const close = async(checkForUnsavedChanges: boolean) => {
            props.dialog.close(checkForUnsavedChanges);
        };

        const minimize = () => {
            props.dialog.minimize();
        };

        useEventListener('mousedown', (event) => {
            if (!props.dialog.minimized && modal.value && dialog.value.state === 'visible' && props.dialog.options.closeOnBackdrop && props.last) {
                const rect = modal.value.getBoundingClientRect();
                if (!(event.clientX >= rect.left && event.clientX <= rect.right) ||
                    !(event.clientY >= rect.top && event.clientY <= rect.bottom)) {
                    close(true);
                }
            }
        });

        const dialogActive = computed(() => dialog.value.state !== 'hidden');

        return {
            // Template REFs
            modal,

            // Animations
            enterActiveClass,
            leaveActiveClass,
            backdropEnterActiveClass,
            backdropLeaveActiveClass,

            // Methods
            modalVisible,
            modalHidden,

            close,
            minimize,

            // Computed
            dialogActive,

            // CSS Variables
            zIndex: dialog.value.zIndex,
        };
    },
});
</script>

<style lang="scss" scoped>
$modalInnerMargin: 3.5rem;

.modal-outer {
    position: absolute;

    z-index: v-bind(zIndex);

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        overscroll-behavior: contain;

        .modal-dialog {
            position: relative;

            &:not(&.full) {
                display: flex;
                align-items: center;

                min-height: calc(100% - #{$modalInnerMargin});
                width: auto;

                margin: 17px auto;
                padding-left: 10px;
                padding-right: 10px;
            }

            pointer-events: none;

            &::before {
                content: "";
                display: block;
                height: calc(100vh - #{$modalInnerMargin});
                height: min-content;
            }

            .modal-content {
                position: relative;
                display: flex;
                flex-direction: column;
                pointer-events: auto;
                outline: 0;
                width: 100%;
                border: solid 1px theme('colors.border');
                background-color: theme('colors.modalBackground');
                border-radius: 1rem;
                padding: 10px;
                white-space: pre-line;
            }

            &.full {
                height: 100%;
                width: 100%;
                overflow: hidden;

                &::before {
                    display: none;
                }

                .modal-content {
                    height: 100%;
                    width: 100%;
                    padding: 0;
                    border: none;
                    border-radius: 0;
                }
            }

            &.max {
                max-width: 95vw;
            }
            
            &.xxl {
                max-width: theme('screens.xxl');
            }

            &.xl {
                max-width: theme('screens.xl');
            }

            &.lg {
                max-width: theme('screens.lg');
            }

            &.md {
                max-width: theme('screens.md')
            }

            &.sm {
                max-width: theme('screens.sm')
            }

            &.xs {
                max-width: 320px;
            }

            .dialog-tray {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--dialog-close-button-color);

                button {

                    &:not(:last-of-type) {
                        margin-right: 5px;
                    }
                }
            }
        }

        z-index: 2;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(#1a1f33, 0.5);
        z-index: 1;
    }
}

.loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
